<template>
  <div :class="screen ? 'homeScreen' : 'home'">
    <div class="swiper-container" :style="'height:' + minHeight + 'px'">
      <div class="swiper-wrapper" :style="'height:' + minHeight + 'px'">
        <div
          class="swiper-slide item-one"
          :style="'height:' + minHeight + 'px'"
        >
          <img src="../../assets/images/phone/home/item1.png" alt="" />
          <!-- <video
            muted
            v-if="!time"
            class="companyVideo"
            ref="companyVideo"
            src="https://knot-website.oss-cn-beijing.aliyuncs.com/video/home.mp4"
          ></video>
          <img
            v-else
            @click="videoControl"
            src="../../assets/images/phone/home/bofang.png"
            alt=""
          /> -->
        </div>
        <div
          class="swiper-slide item-two"
          :style="'height:' + minHeight + 'px'"
        >
          <img src="../../assets/images/phone/home/neirong@2x.png" alt="" />
        </div>
        <div
          class="swiper-slide item-three"
          :style="'height:' + minHeight + 'px'"
        >
          <div class="top">
            <h1>指云端-智慧医疗</h1>
            <p>MEDICAL WISDOM</p>
          </div>
          <div class="bottom">
            <div
              @click="gotoPage(item.id)"
              v-for="item in wisdomList"
              :key="item.id"
              class="wisdom-item"
            >
              <img :src="item.url" alt="" />
              <h1>{{ item.title }}</h1>
              <p>{{ item.text }}</p>
              <p>{{ item.text1 }}</p>
              <p>{{ item.text2 }}</p>
            </div>
          </div>
        </div>
        <div
          class="swiper-slide item-four"
          :style="'height:' + minHeight + 'px'"
        >
          <div>
            <div class="title">
              <h1>成都市健康医疗大数据平台 云影像提供方</h1>
              <img src="../../assets/images/phone/home/udi.png" alt="" />
              <img src="../../assets/images/phone/home/udi.png" alt="" />
              <p>
                与中电合作，承建成都市市属医疗机构的云影像建设工作，成为全国首个副省级城市云影像建设提供方。
              </p>
            </div>
            <div class="img">
              <img src="../../assets/images/phone/home/zhongdian.png" alt="" />
            </div>
          </div>
        </div>
        <div
          class="swiper-slide item-five"
          :style="'height:' + minHeight + 'px'"
        >
          <div>
            <div class="title">
              <h1>我们的合作伙伴</h1>
              <p>COOPERATIVE PARTNER</p>
            </div>
            <img src="../../assets/images/phone/home/bj6_hzhb@2x.png" alt="" />
          </div>
        </div>
        <div
          class="swiper-slide item-six"
          :style="'height:' + minHeight + 'px'"
        >
          <Footer />
        </div>
        <!--点击下一页 -->
      </div>
      <i
        @click="turnPages"
        v-if="active != 5"
        class="el-icon-d-arrow-right"
      ></i>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import Footer from '../../components/Appfooter/index.vue'
export default {
  components: {
    Footer
  },
  props: {
    minHeight: Number
  },
  data() {
    return {
      screen: false,
      swiper: null,
      active: 0,
      wisdomList: [
        {
          id: 1,
          url: require('@/assets/images/phone/home/yunyingxiang.png'),
          text: '云影像，云超声，云心电',
          title: '云影像'
        },
        {
          id: 2,
          url: require('@/assets/images/phone/home/yuncunchu.png'),
          text: '影像云归档、影像数据异地容灾备份、PACS应用级备份',
          title: '影像云存储'
        },
        {
          id: 3,
          url: require('@/assets/images/phone/home/yiliaoxietong.png'),
          text: '远程影像诊断',
          text1: '远程阅片',
          text2: '区域医疗云影像',
          title: '医疗协同'
        }
      ],
      time: true,
      autoplay: true
    }
  },
  methods: {
    turnPages() {
      console.log(this.active, 'this.active')
      this.swiper.slideTo(this.active + 1, 1000, true)
    },
    gotoPage(id) {
      console.log(id)
      if (id === 1) {
        this.$router.push('/phone/product/couldDicom')
        return
      }
      if (id === 2) {
        this.$router.push('/phone/product/dicomCloudStorage')
      } else {
        this.$router.push('/phone/product/regionalCloudDicom')
      }
    },
    calCss() {
      // alert(window.innerHeight / window.innerWidth)
      if (window.innerHeight / window.innerWidth < 1.6) {
        //  宽高比小于1.6，为避免显示问题使用另一个CSS文件
        this.screen = true
      } else {
        this.screen = false
      }
    }
  },
  created() {},
  mounted() {
    var that = this
    this.swiper = new Swiper('.swiper-container', {
      // 速度
      speed: 1000,
      //  mousewheelControl: true,
      // 前进和后退的按钮
      prevButton: '.swiper-button-prev',
      nextButton: '.swiper-button-next',
      pagination: '.swiper-pagination',
      paginationType: 'bullets',
      centerInsufficientSlides: true,
      // 居中显示
      loopFillGroupWithBlank: true,
      // 展示张数
      slidesPerView: 'auto',
      direction: 'vertical',
      onSlideChangeEnd: function (swiper) {
        console.log(swiper, '当前选中的标签页面的下标')
        that.active = swiper.activeIndex
        console.log(that.active)
      }
    })
    this.calCss()
  }
}
</script>

<style lang="less" scoped>
.home {
  background-color: #0b203b;

  .swiper-wrapper {
    .item-one {
      background: url('../../assets/images/phone/home/beijing1.png') no-repeat;
      background-size: cover;
      display: flex;
      flex: row;
      justify-content: center;
      align-items: center;
      img {
        width: 555px;
        height: 429px;
      }
    }
    .item-two {
      background: url('../../assets/images/phone/home/beijing66.png') no-repeat;
      width: 100%;
      background-size: cover;
      // position: relative;
      img {
        width: 70%;
        
        // height: 50%;
        // margin-top: 30px;
        position: absolute;
        top:25%;
        left: 16%;
        object-fit: cover;
      }
    }
    .item-three {
      background: url('../../assets/images/phone/home/beiinjingwu.png')
        no-repeat;
      width: 100%;
      background-size: 100% 100%;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-around;
      .top {
        // margin-top: 62px;
        text-align: center;
        color: #fff;
        margin-top: 10%;
        h1 {
          font-size: 52px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 47px;
        }
        p {
          font-size: 19px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #ffffff;
          opacity: 0.6;
          padding-top: 10px;
          margin-bottom: 0;
        }
      }
      .bottom {
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        margin-top: 6%;
        .wisdom-item {
          text-align: center;
          margin-top: 40px;
          img {
            height: 137px;
            width: 137px;
          }
          h1 {
            margin-top: 20px;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
          }
          p {
            font-size: 28px;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #ffffff;
            width: 300px;
            line-height: 36px;
            margin: auto;
          }
        }
      }
    }
    .item-four {
      background: url('../../assets/images/phone/home/beijingqi.png') no-repeat;
      width: 100%;
      background-size: 100% 100%;
      .title {
        padding-top: 210px;
        padding-left: 88px;
        width: 480px;
        h1 {
          font-size: 40px;
          font-family: Adobe Heiti Std;
          // font-weight: normal;
          line-height: 66px;
          font-weight: 700;
          color: #14d4f1;
        }
        img {
          width: 37px;
          height: 34px;
          margin-right: 9px;
        }
        p {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #ffffff;
          line-height: 42px;
          letter-spacing: 2px;
        }
      }
      .img {
        padding-top: 80px;
        text-align: center;
        img {
          width: 444px;
          height: 397px;
        }
      }
    }
    .item-five {
      background: url('../../assets/images/phone/home/beijingba.png') no-repeat;
      width: 100%;
      background-size: 100% 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        // padding-top: 10%;
        padding-bottom: 5%;
        h1 {
          font-size: 52px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 38px;
        }
        p {
          font-size: 32px;
          font-family: AngsanaUPC;
          font-weight: 400;
          color: #ffffff;
          line-height: 62px;
          opacity: 0.55;
        }
      }
      img {
        width: 601px;
        height: 774px;
      }
    }
    .item-six {
      background: url('../../assets/images/phone/home/sy_bj7@2x_.png') no-repeat;
      width: 100%;
      background-size: 100% 100%;
    }
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .el-icon-d-arrow-right {
    z-index: 1000;
    font-size: 50px;
    transform: rotate(90deg);
    color: #c3cdd6;
    position: fixed;
    bottom: 20px;
    right: 48%;
    cursor: pointer;
  }
}

.homeScreen {
  background-color: #0b203b;

  .swiper-wrapper {
    .item-one {
      background: url('../../assets/images/phone/home/beijing1.png') no-repeat;
      background-size: cover;
      display: flex;
      flex: row;
      justify-content: center;
      align-items: center;
      img {
        width: 330px;
      }
    }
    .item-two {
      background: url('../../assets/images/phone/home/beijing66.png') no-repeat;
      width: 100%;
      // background-size: 100% 100%;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 400px;
        
      }
    }
    .item-three {
      background: url('../../assets/images/phone/home/beiinjingwu.png')
        no-repeat;
      width: 100%;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .top {
        text-align: center;
        color: #fff;
        h1 {
          font-size: 40px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
        p {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #ffffff;
          opacity: 0.6;
          padding-top: 5px;
          margin-bottom: 0;
        }
      }
      .bottom {
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 50%;
        .wisdom-item {
          text-align: center;
          margin-top: 30px;
          img {
            height: 100px;
            width: 100px;
          }
          h1 {
            margin-top: 5px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
          }
          p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            width: 300px;
            line-height: 30px;
            margin: auto;
          }
        }
      }
    }
    .item-four {
      background: url('../../assets/images/phone/home/beijingqi.png') no-repeat;
      width: 100%;
      background-size: 100% 100%;
      .title {
        padding-top: 150px;
        padding-left: 100px;
        width: 450px;
        h1 {
          font-size: 35px;
          font-family: Adobe Heiti Std;
          // font-weight: normal;
          line-height: 66px;
          font-weight: 700;
          color: #14d4f1;
        }
        img {
          width: 37px;
          height: 34px;
          margin-right: 9px;
        }
        p {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #ffffff;
          line-height: 40px;
          letter-spacing: 3px;
        }
      }
      .img {
        padding-top: 80px;
        text-align: center;
        img {
          width: 444px;
          height: 397px;
        }
      }
    }
    .item-five {
      background: url('../../assets/images/phone/home/beijingba.png') no-repeat;
      width: 100%;
      background-size: 100% 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 100px;
      justify-content: space-around;
      .title {
        h1 {
          font-size: 40px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
        p {
          margin-top: 30px;
          font-size: 24px;
          font-family: AngsanaUPC;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.55;
        }
      }
      img {
        margin-top: 30px;
        width: 70%;
      }
    }
    .item-six {
      background: 200px;
      height: 50px;
      background: url('../../assets/images/phone/home/sy_bj7@2x_.png') no-repeat;
      width: 100%;
      background-size: 100% 100%;
    }
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .el-icon-d-arrow-right {
    z-index: 1000;
    font-size: 50px;
    transform: rotate(90deg);
    color: #c3cdd6;
    position: fixed;
    bottom: 20px;
    right: 48%;
    cursor: pointer;
  }
}
</style>
